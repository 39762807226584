function BgVideo({ muted }) {
  return (
    <div className="background">
      <video
        className="bgvideo"
        width={window.innerWidth}
        height={window.innerHeight}
        autoPlay
        playsInline
        loop
        muted={muted}
        id="bg-video"
      >
        <source src="/Videos/loop.mp4" type="video/mp4" />
        <source src="./loop_out.mp3" type="audio/mp3" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default BgVideo;
