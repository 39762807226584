import React, { useState } from "react";

const API_URL =
  "https://us-central1-wallet-search-968b3.cloudfunctions.net/searchWallet/searchWallet";

function InitialForm({ playSound }) {
  const [wallet, setWallet] = useState("");
  const [title, setTitle] = useState();
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    setTitle("...");
    setLoading(true);
    try {
      if (!wallet) return;
      const res = await fetch(`${API_URL}?wid=${wallet.trim()}`);
      const data = await res.json();
      if (data?.status) {
        setTitle("You are on the list");
      } else {
        setTitle("You are not on the list");
      }
    } catch (e) {
      // console.log("error", e);
    }
    setLoading(false);
  };

  return (
    <div className="content">
      {!title ? (
        <div className="row">
          <input
            type="text"
            placeholder="Check if you rise"
            onChange={(e) => setWallet(e.target.value)}
            value={wallet}
            onFocus={playSound}
          />
          <button onClick={onClick} disabled={loading}>
            Check
          </button>
        </div>
      ) : (
        <h3 className="title">{title}</h3>
      )}
    </div>
  );
}

export default InitialForm;
