import { useState } from "react";
import BgVideo from "./components/BgVideo";
import InitialForm from "./components/InitialForm";

function App() {
  const [muted, setMuted] = useState(true);

  return (
    <div className="App">
      <BgVideo muted={muted} />
      <InitialForm playSound={() => setMuted(false)} />
    </div>
  );
}

export default App;
